import React from "react";
import Select, { components } from "react-select";
import { navigate } from "gatsby";

export default ({ languages = {}, langCode, urlPath }) => {
  return null; // @temp
  const { Option } = components;

  const sort = (a, b) => (a === langCode ? -1 : b === langCode ? 1 : 0);
  const options = Object.keys(languages).sort(sort).map((item, i) => ({
    value: item,
    label: languages[item],
    icon: item,
  }));

  const IconOption = (props) => (
    <Option {...props}>
      <img
        src={require('../images/icons/' + props.data.icon + '.png')}
        style={{ height: 20, width:28, margin: 2 }}
        alt={props.data.label}
      />
      {" "+props.data.label}
    </Option>
  );

  const onSwitchLang = ({value}) => {
    const prefix = (value === 'en') ? '' : '/' + value;
    const path = (prefix && urlPath === '/') ? '' : urlPath;
    navigate(prefix + path);
  };

  return (options.length > 1) && (
    <div className="container position-relative">
      <div className="lang-switcher col-lg-2 col-5">
        <Select
          classNamePrefix="obw"
          className={options.value}
          defaultValue={options.value}//options[0]
          value={options.value}
          options={options}
          placeholder={languages[langCode]}
          onChange={onSwitchLang}
          isSearchable={false}
          components={{ Option: IconOption}}
        />
      </div>
    </div>
  );
}
