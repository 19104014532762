/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {useStaticQuery, graphql, navigate} from "gatsby"

import Header from "./Header"
import Footer from "./Footer";
import MenuStatic from "./MenuStatic";
import ContactBlock from "./ContactBlock";
import SliderDefault from "./SliderDefault";
import Social from "../components/Social"

import "../sass/style.scss"
import LangSwitcher from "./LangSwitcher";

/**
 *
 * @param children
 * @param translations - Inherited from node.js component (Drupal node data)
 * @param lang - Inherited from node.js component (Drupal node data)
 * @param urlPath - Inherited from node.js component (Drupal node data)
 * @returns {JSX.Element}
 * @constructor
 */
const Layout = ({ children, languages, langCode = '', urlPath = '', menus }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div id="main-container">
        <LangSwitcher languages={languages} langCode={langCode} urlPath={urlPath} />
        <Header siteTitle={data.site.siteMetadata?.title || `Title`} langCode={langCode} />
        <MenuStatic menus={menus} langCode={langCode} />
        { children }
        <ContactBlock />
        <SliderDefault />
        <Social />
        <Footer menus={menus} langCode={langCode} />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
